<template>
  <div>
    <v-card
      style="
        margin-top: 20px;
        margin-right: 10px;
        margin-left: 10px;
        margin-bottom: 30px;
        padding: 10px 30px;
      "
    >
      <v-row no-gutters>
        <v-col cols="12" style="margin-top: 10px; margin-bottom: 20px">
          <div
            style="border-radius: 10px 10px 0 0"
            :class="`d-flex justify-start ${
              wWidth < 769 ? 'flex-column' : 'flex-row'
            }`"
          >
            <v-toolbar-title
              >List RAB
              <v-tooltip bottom color="grey">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    x-small
                    text
                    icon
                    color="grey darken-2"
                    @click="refreshData"
                  >
                    <v-icon small>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>refresh page</span>
              </v-tooltip></v-toolbar-title
            >
            <v-divider class="mx-6" inset vertical></v-divider>
            <div
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <router-link to="/e-catalogue/rab/submission">
                <v-btn
                  v-if="wWidth > 768"
                  outlined
                  small
                  elevation="1"
                  color="indigo"
                  class="indigo--text"
                  style="font-size: 12px; margin-right: 40px"
                >
                  Buat Baru
                </v-btn>
              </router-link>
            </div>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="d-flex justify-start align-center">
            <div
              style="margin-top: 5px; margin-bottom: 15px; margin-right: 15px"
            >
              <v-autocomplete
                :disabled="selectedData.length === 0"
                v-model="actionValue"
                :items="[
                  { id: 0, name: '' },
                  { id: 1, name: 'Delete' }
                ]"
                label="Action"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height: 42px; width: 120px"
              ></v-autocomplete>
            </div>
            <div
              style="margin-top: 5px; margin-bottom: 15px; margin-right: 10px"
            >
              <v-autocomplete
                v-model="paramAPI.company_id"
                :items="dropdown.company"
                label="- Perusahaan -"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height: 42px; width: 180px"
                clearable
              ></v-autocomplete>
            </div>
            <div
              style="margin-top: 5px; margin-bottom: 15px; margin-right: 10px"
            >
              <v-autocomplete
                v-model="paramAPI.project_id"
                :items="dropdown.project"
                label="- Project -"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height: 42px; width: 180px"
                clearable
              ></v-autocomplete>
            </div>
            <div
              style="margin-top: 5px; margin-bottom: 15px; margin-right: 10px"
            >
              <v-autocomplete
                v-model="paramAPI.status"
                :items="dropdown.status"
                label="- Status -"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height: 42px; width: 180px"
                clearable
              ></v-autocomplete>
            </div>
            <!-- <div
              style="height:42px;margin-top:5px; margin-bottom:15px; margin-right:10px;"
            >
              <v-text-field
                class="ml-1 mr-2"
                dense
                label="Tanggal"
                type="date"
                name="startdate"
                style="height:30px;"
                step="1"
                outlined
                clearable
              />
            </div> -->
            <div
              style="
                margin-left: auto;
                height: 42px;
                width: 320px;
                margin-top: 5px;
                margin-bottom: 15px;
                margin-right: 10px;
              "
            >
              <v-text-field
                v-model="paramAPI.keyword"
                @keyup.enter="searchEnter"
                label="Cari disini"
                type="cari"
                outlined
                dense
                append-icon="mdi-magnify"
              ></v-text-field>
            </div>
          </div>
        </v-col>

        <v-col cols="12">
          <v-data-table
            v-model="selectedData"
            :headers="headers"
            :items="result"
            :page="paramAPI.page"
            :items-per-page="paramAPI.itemsPerPage"
            @click:row="rowClick"
            @update:page="updatePage"
            @update:items-per-page="updateItemPerPage"
            :footer-props="{
              showFirstLastPage: true,
              showCurrentPage: true,
              itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
            }"
            item-key="id"
            show-select
            mobile-breakpoint="0"
            class="elevation-1"
            style="cursor: pointer"
          >
            <template v-slot:[`item.pic_employee`]="{ item }">
              <td>
                {{ item.employee.name }}
              </td>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <td :style="`color:${colorStatus(item.status.id)}`">
                {{ item.status.name }}
              </td>
            </template>
            <template v-slot:[`item.total_budget_plan`]="{ item }">
              <div :style="`font-size:14px; width:80px; padding: 0;`">
                {{ priceConvert(item.total_budget_plan) }}
              </div>
            </template>
            <template v-slot:[`item.department`]="{ item }">
              <td>
                {{ item.department.name }}
              </td>
            </template>
            <template v-slot:[`item.company`]="{ item }">
              <td>
                {{ item.company.name }}
              </td>
            </template>
            <template v-slot:[`item.project`]="{ item }">
              <td>
                {{ item.project.name }}
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>

    <!-- <div style="position:fixed; bottom:10px; right:0;">
      <Scanner />
    </div> -->
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import buildType from '../../../services/buildType'
// import * as XLSX from 'xlsx/xlsx.mjs'
export default {
  components: {
    // Scanner: () => import('../home/components/Scanner.vue')
  },
  data: () => ({
    wWidth: window.innerWidth,
    proc: buildType.apiURL('proc'),
    eSanqua: buildType.apiURL('esanqua'),
    hrsApi: buildType.apiURL('hrs'),
    paramAPI: {
      page: 1,
      keyword: '',
      sortBy: 'created_at',
      sortType: 'desc',
      offset: 0,
      limit: 10,
      length: 0,
      itemsPerPage: 10,
      company_id: null,
      date: null,
      project_id: null,
      status: null
    },
    totalData: 0,
    headers: [
      {
        text: 'Project',
        value: 'project',
        align: 'left',
        sortable: false
      },
      {
        text: 'No. RAB',
        value: 'budget_no',
        align: 'left',
        sortable: false
      },
      {
        text: 'Nama',
        value: 'name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Unit/Plant',
        value: 'company',
        align: 'left',
        sortable: false
      },
      {
        text: 'Department',
        value: 'department',
        align: 'left',
        sortable: false
      },
      {
        text: 'Penanggung Jawab',
        value: 'pic_employee',
        align: 'left',
        sortable: false
      },
      {
        text: 'Total Budget',
        value: 'total_budget_plan',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tgl. Buat',
        value: 'created_at',
        align: 'left',
        sortable: false
      },
      {
        text: 'Tgl. Submit',
        value: 'submited_at',
        align: 'left',
        sortable: false
      },
      {
        text: 'Status',
        value: 'status',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    detail: null,
    selected: null,
    loading: true,
    // dropdownCompany: [],
    dropdown: {
      company: [],
      department: [],
      project: [],
      status: []
    },
    isDisableCompanyDropdown: false,
    actionValue: 0,
    selectedData: []
  }),
  watch: {
    'paramAPI.sortBy'() {
      this.getDataFromApi()
    },
    'paramAPI.sortType'() {
      this.getDataFromApi()
    },
    'paramAPI.status'() {
      this.getDataFromApi()
    },
    'paramAPI.company_id'() {
      this.dropdownProject()
      this.getDataFromApi()
    },
    'paramAPI.project_id'() {
      this.getDataFromApi()
    },
    actionValue(val) {
      switch (val) {
        case 1:
          this.deleteRab()
          break
      }
    }
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getFpbProjectParam'])
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    this.paramAPI.company_id = Number(
      this.getUserProfile.employee.company.plant_id
    )
    // get latest parameter
    // if (this.getFpbParam != null) {
    //   this.paramAPI = this.getFpbParam
    // }
    setTimeout(async () => {
      await this.getDataFromApi()
      await this.initDropdown()
      this.setAction()
    }, 200)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    refreshData() {
      this.getDataFromApi()
    },
    setAction() {
      this.actionValue = 0
      this.selectedData = []
    },
    onResize() {
      this.wWidth = window.innerWidth
    },
    addItem() {
      // this.initDropdown()
      setTimeout(() => {
        document.getElementById('rabFormCreate').click()
      }, 500)
    },
    rowClick(pValue, pSlot) {
      // const arrItem = {
      //   list: this.result
      // }
      // this.$store.commit('setProjectList', arrItem)

      setTimeout(() => {
        this.$router.push(
          `/e-catalogue/rab/detail/${pValue.id}?idx=${pSlot.index}`
        )
      }, 200)
    },
    async changeCompany(p) {
      this.paramAPI.company_id = p

      this.clearPaging()
      await this.getDataFromApi()
    },
    startDateWatcher() {
      this.clearPaging()
      this.getDataFromApi()
    },
    endDateWatcher() {
      this.clearPaging()
      this.getDataFromApi()
    },
    async initDropdown() {
      this.getDropdownCompany()
      this.dropdownProject()
      this.dropdownStatus()
      // await this.getDropdownDepartment()
    },
    async getDataFromApi() {
      this.loading = true
      await this.initDataTable()
        .then((data) => {
          if (data.status_code === '00') {
            this.totalData = Number(data.total_record)
            return (this.result = data.data)
          } else {
            this.totalData = 0
            return (this.result = [])
          }
        })
        .catch((err) => {
          console.log(err)
          return (this.result = [])
        })

      setTimeout(() => {
        this.loading = false
      }, 100)
    },
    initDataTable() {
      let url = `${this.proc}rab/list?keyword=${this.paramAPI.keyword}&offset=${
        this.paramAPI.offset * this.paramAPI.limit
      }&limit=${this.paramAPI.limit}&order_by=${
        this.paramAPI.sortBy
      }&order_type=${this.paramAPI.sortType}`

      const filter = []
      // &status=${this.paramAPI.status}
      if (this.paramAPI.company_id !== null) {
        url = url + `&company_id=${this.paramAPI.company_id}`
      }
      if (this.paramAPI.project_id !== null) {
        filter.push(`{"project_id":${this.paramAPI.project_id}}`)
      }
      if (this.paramAPI.status !== null) {
        filter.push(`{"status":${this.paramAPI.status}}`)
      }
      url = url + `&filter=[${filter}]`

      this.url = url
      // this.$store.commit('setProjectItems', this.paramAPI)
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((res) => {
            console.log('list >>>', res)
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    deleteRab() {
      this.showMsgDialog(
        'question',
        `${this.selectedData.length} data akan dihapus secara permanent, Anda yakin ?`,
        true
      ).then((res) => {
        if (res.isConfirmed) {
          this.startDelete()
        } else {
          this.actionValue = 0
        }
      })
    },

    async startDelete() {
      const arrSuccess = []
      const arrFail = []
      let i = 0
      while (
        (await new Promise((resolve, reject) => {
          axios
            .delete(`${this.proc}rab/delete/${this.selectedData[i].id}`)
            .then((res) => {
              if (res.data.status_code === '00') {
                arrSuccess.push({
                  index: i,
                  msg: res.data.status_msg
                })
              } else {
                arrFail.push({
                  index: i,
                  msg: res.data.status_msg
                })
              }
              resolve(i++)
            })
            .catch((err) => {
              console.log(err)
              arrFail.push({
                index: i,
                msg: 'error'
              })
              reject(new Error(i++))
            })
        })) <
        this.selectedData.length - 1
      ) {
        console.log('cek ' + i)
      }

      this.actionValue = 0
      this.showMsgDialog(
        'success',
        `${arrSuccess.length} data berhasil terhapus <br/> ${arrFail.length} data gagal terhapus pada index [${arrFail}]`,
        false
      )
      return this.getDataFromApi()
    },
    async getDropdownCompany() {
      const level = this.getUserProfile.level.find(
        ({ id }) =>
          id === '3' || id === '14' || id === '23' || id === '27' || id === '41'
      )
      if (level !== undefined) {
        if (level.user_user_level !== null) {
          if (
            level.user_user_level.allowed_company === null ||
            level.user_user_level.allowed_company.length === 0
          ) {
            this.dropdownCompanyApi()
          } else {
            this.dropdown.company = level.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          this.dropdownCompanyApi()
        }
      } else {
        this.dropdownCompanyApi()
      }
    },
    async dropdownCompanyApi() {
      if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
        this.isDisableCompanyDropdown = true
      } else {
        this.isDisableCompanyDropdown = false
      }
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          this.dropdown.company = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    dropdownProject() {
      this.dropdown.project = []
      let url = `${this.proc}globalmaster/project/dropdown?keyword=`

      const filter = []
      // &status=${this.paramAPI.status}
      if (this.paramAPI.company_id !== null) {
        filter.push(`{"company_id":${this.paramAPI.company_id}}`)
      }
      url = url + `&filter=[${filter}]`
      axios
        .get(url)
        .then((res) => {
          console.log('project >>>', res)
          this.dropdown.project = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    dropdownStatus() {
      this.dropdown.status = [
        { id: 0, name: 'Draft' },
        { id: 1, name: 'Waiting Approval' },
        { id: 2, name: 'Pending' },
        { id: 3, name: 'In Progress' },
        { id: 4, name: 'Done' },
        { id: 5, name: 'Cancel' },
        { id: 6, name: 'Reject' }
      ]
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.itemsPerPage = p
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    async searchEnter() {
      await this.getDataFromApi()
    },
    clearPaging() {
      this.paramAPI.page = 1
      this.paramAPI.offset = 0
      this.paramAPI.limit = 10
      this.paramAPI.itemsPerPage = 10
    },
    colorStatus(val) {
      switch (val) {
        case 0:
          return '#1976d2'
        case 1:
          return 'orange'
        case 2:
          return 'grey'
        case 3:
          return '#4CAF50'
        case 4:
          return 'green'
        case 5:
          return 'red'
        case 6:
          return 'red'
        default:
          return 'black'
      }
    },
    priceConvert(xVal) {
      const num = 0
      if (xVal !== undefined) {
        if (xVal !== null) {
          let round = xVal
          if (round.toString().includes('.')) {
            round = round.toFixed(2)
          }
          const val = round + ''
          let result = ''

          if (val.includes('.')) {
            const splitVal = val.split('.')
            const main = splitVal[0]
            const decimal = splitVal[1]
            result =
              main.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',') +
              `.${decimal}`
          } else {
            result = val
              .toString()
              .replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
          }
          return result
        }
      }
      return num
    },

    showMsgDialog(pModalType, pStatusMsg, pBtnCancel, input) {
      const settings = {
        position: 'center',
        icon: pModalType,
        html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
        showConfirmButton: true,
        showCancelButton: pBtnCancel,
        cancelButtonText: 'No'
      }

      if (input !== undefined) {
        if (input) {
          Object.assign(settings, {
            inputPlaceholder: 'Tuliskan keterangan',
            input: 'text'
            // inputAttributes: {
            //   autocapitalize: 'false'
            // }
          })
        }
      }

      return new Promise((resolve) => {
        this.$swal.fire(settings).then((r) => {
          resolve(r)
        })
      })
    }
    // async exportData() {
    //   const url = this.url + '&is_export=true'
    //   await axios
    //     .get(url)
    //     .then(res => {
    //       console.log(res)
    //       let selectedData = []
    //       const response = res.data.data
    //       if (response !== undefined) {
    //         for (let i = 0; i < response.length; i++) {
    //           selectedData.push({
    //             budget_no: response[i].budget_no,
    //             employee: response[i].employee.name,
    //             department: response[i].department.name,
    //             company: response[i].company.name,
    //             submit_date: response[i].requested_at,
    //             status: response[i].status.name,
    //             created_at: response[i].created_at,
    //             item: response[i].item
    //           })
    //         }
    //         this.exportNow(selectedData)
    //       } else {
    //         selectedData = []
    //       }
    //       return null
    //     })
    //     .catch(error => {
    //       console.log(error)
    //       return null
    //     })
    // },
    // exportNow(selectedData) {
    //   const arrData = []
    //   for (let i = 0; i < selectedData.length; i++) {
    //     const param = {
    //       budget_no: selectedData[i].budget_no,
    //       employee: selectedData[i].employee,
    //       department: selectedData[i].department,
    //       company: selectedData[i].company,
    //       submit_date: selectedData[i].submit_date,
    //       created_at: selectedData[i].created_at,
    //       status_fpb: selectedData[i].status,
    //       product_code: selectedData[i].item.product_code,
    //       product_name: selectedData[i].item.product_name,
    //       qty: selectedData[i].item.qty,
    //       uom: selectedData[i].item.uom_name,
    //       quotation_per_unit: selectedData[i].item.quotation_price_per_unit,
    //       budget_per_unit: selectedData[i].item.budget_price_per_unit,
    //       budget_total: selectedData[i].item.budget_price_total,
    //       last_price: selectedData[i].item.last_price,
    //       date_use: selectedData[i].item.estimate_date_use,
    //       pr_no: selectedData[i].item.pr_no,
    //       status_item: selectedData[i].item.status,
    //       description: selectedData[i].item.description
    //     }
    //     arrData.push(param)
    //   }
    //   this.downloadExcell(arrData)
    // },
    // downloadExcell(arrData) {
    //   const data = XLSX.utils.json_to_sheet(arrData)
    //   const wb = XLSX.utils.book_new()
    //   XLSX.utils.book_append_sheet(wb, data, 'data')
    //   const date = new Date()
    //   XLSX.writeFile(wb, `FPB_${date.toLocaleDateString('id')}.xlsx`)
    // },
    // price(pItem) {
    //   if (pItem !== undefined) {
    //     if (pItem !== null) {
    //       return pItem.toString().replace(/\B(?<!\\d*)(?=(\d{3})+(?!\d))/g, ',')
    //     }
    //   }
    //   return ''
    // }
  }
}
</script>

<style lang="scss" scoped></style>
